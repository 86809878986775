require('popper.js')
require('bootstrap')

$(function() {
    var header = $(".topHeader");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 75) {
            header.removeClass('topHeader').addClass("fixedHeader");
        } else {
            header.removeClass("fixedHeader").addClass('topHeader');
        }
    });
    var btn = $('#button-top');

    $(window).scroll(function() {
      if ($(window).scrollTop() > 600) {
        btn.addClass('show');
      } else {
        btn.removeClass('show');
      }
    });

    btn.on('click', function(e) {
      e.preventDefault();
      $('html, body').animate({scrollTop:0}, '300');
    });
});